
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Footer {
  position: relative;
  padding-top: spacing(2);
  padding-bottom: spacing(2);
  font-size: spacing(2);
  color: $color-grey20;

  @include mq($breakpoint-tablet) {
    padding-top: spacing(4);
    padding-bottom: spacing(8);
    text-align: center;
  }

  &.FooterHomepage {
    padding-bottom: spacing(37);

    @include mq($breakpoint-desktop) {
      padding-bottom: spacing(30);
    }

    @include mq($breakpoint-desktopLarge) {
      padding-bottom: spacing(10);
    }
  }
}
.JP {
  font-family: $font-body-jp;
}
.FooterContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: spacing(160 - 8);
  padding-left: spacing(4);
  padding-right: spacing(4);

  text > * + * {
    margin-top: spacing(2);
  }
}

.FooterBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: $breakpoint-desktopMedium;
  margin: auto;
  z-index: -1;
}

.FooterInner {
  position: relative;

  > * + * {
    margin-top: spacing(2);
  }

  @include mq($breakpoint-desktop) {
    display: flex;
    align-items: center;

    > * {
      flex: 1 1 50%;
    }
  }
}

.FooterLogo {
  flex: 0 1 auto;
  order: 1;
  padding-left: spacing(4);
  padding-right: spacing(4);
  text-align: center;

  @include mq($breakpoint-desktopMedium) {
    a > div {
      width: spacing(15) !important;
      height: spacing(15) !important;
    }
  }

  @include mq($breakpoint-desktopMedium) {
    padding-left: spacing(10);
    padding-right: spacing(10);
  }
}

.FooterList {
  list-style: none;

  a {
    display: inline-block;
    padding-top: spacing(0.5);
    padding-bottom: spacing(0.5);
    color: $color-grey20;
    text-decoration: none;
    font-weight: 700;

    &:hover,
    &:focus {
      color: $color-grey20;
      text-decoration: underline;
    }

    &.active {
      text-decoration: underline;
    }
  }

  @include mq($breakpoint-tablet) {
    display: inline-flex;

    > * + * {
      margin-top: 0;
      margin-left: spacing(2);
    }
  }

  @include mq($breakpoint-desktop) {
    display: flex;
    justify-content: flex-start;

    > * + * {
      margin-left: spacing(4);
    }
  }
}

.FooterPrimary {
  order: 0;
}

.FooterSecondary {
  order: 2;

  > * + * {
    margin-top: spacing(2);
  }

  @include mq($breakpoint-desktop) {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > * {
      margin-top: 0;
    }
  }
}

// .FooterSocial {}

.FooterSocialTitle {
  display: inline-block;
  margin-right: spacing(2);
  @include mq($breakpoint-desktop) {
    display: none;
  }
  @include mq(1150px) {
    display: inline-block;
  }
}

.FooterSmall {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  margin-top: spacing(2);

  @include mq($breakpoint-tablet) {
    margin-top: spacing(4);
  }

  a {
    color: currentColor;
  }
}
